.app-container {
  background-color: #000000e3;
  height: 100vh;
  width: 99vw;
  
}

.nav-bar {
  background-color: #000000;
  padding: 20px;
}

.logo img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding-left: 600px; 
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');



:root {
    
   
    
    /* Primary */
    --var-soft-blue: hsl(215, 51%, 70%);
    --var-cyan: hsl(178, 100%, 50%);
    /* Neutral */
    --var-main-darkest: hsl(217, 54%, 11%);
    --var-card-dark: hsl(216, 50%, 16%);
    --var-line-dark: hsl(215, 32%, 27%);
    --var-lightest: white;
    
    /* Fonts */
    
    --var-heading: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
    
    --var-small-heading: normal normal 400 1em/1em 'Outfit', sans-serif;
    
    --var-para: normal normal 300 1em/1.55em 'Outfit', sans-serif;
}






body {
    background-color: var(--var-main-darkest);
}


img {
    width: 100%;
    height: 100% ;
  
    border-radius: 25px;
    object-fit: cover;
   
  
}

a {
    color: inherit;
}



h1 {
    font: var(--var-heading);
    color: var(--var-lightest);
    padding: 1.2em 0;
}

h2 {
    font: var(--var-small-heading);
    color: var(--var-lightest);
}

p {
    font: var(--var-para);
    color: var(--var-soft-blue);
}



.card-container {
 
 
    background-color: var(--var-card-dark);
  
    padding: 2rem;
    display: flex;
  flex-wrap: wrap;
  --var-main-darkest: hsl(217, 54%, 11%);
  --var-card-dark: hsl(216, 50%, 16%);
  --var-line-dark: hsl(215, 32%, 27%);
  --var-lightest: white;
  
  justify-content: center;

}
.card-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 25px;
}

.card {
  width: 350px;
  height: 400px;
  margin: 20px;
  display: inline-block;
  background-color: var(--var-card-dark);;
  transition: 0.3s;
  border-radius: 25px;
  
}



div.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.coin-base, .time-left, .card-attribute {
    display: flex;
    align-items: center;
    padding: 1em 0;
}

.card-attribute {
    padding-bottom: 1.5em;
    border-top: 2px solid var(--var-line-dark);
}

a.hero-image-container {
    position: relative;
    display: block;
    
}


img.eye {
    position: absolute;
    width: 100%;
    max-width: 2em;
    top: 44%;
    left: 43%;
}

@media (min-width:400px) {
  img.eye {
    max-width: 3em;
}
}

.hero-image-container::after {
    content: '';
    background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 5rem;
    background-color: hsla(178, 100%, 50%, 0.3);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.hero-image-container:hover::after {
  opacity: 1;
}

.small-image {
    width: 1.2em;
    margin-right: .5em;
}

.small-avatar {
    width: 2em;
    border-radius: 200px;
    outline: 2px solid white;
    margin-right: 1.4em;
}

div.attribution {
    margin: 0 auto;
    width: 100%;
    font: var(--var-para);
    text-align: center;
    padding: 1.5em 0 4em 0;
    color: var(--var-line-dark);
}
.attribution a {
    color: var(--var-soft-blue);
}

@media (min-width:600px) {
    body {
        font-size: 18px;
    }
}

.same-size-img {
    width: 350px; 
    height: 220px; 
    object-fit: cover;

  }

  .site-footer
  {
    background-color:#26272b;
    padding:45px 0 20px;
    font-size:15px;
    line-height:24px;
    color:#737373;
  }
  .site-footer hr
  {
    border-top-color:#bbb;
    opacity:0.5
  }
  .site-footer hr.small
  {
    margin:20px 0
  }
  .site-footer h6
  {
    color:#fff;
    font-size:16px;
    text-transform:uppercase;
    margin-top:5px;
    letter-spacing:2px
  }
  .site-footer a
  {
    color:#737373;
  }
  .site-footer a:hover
  {
    color:#3366cc;
    text-decoration:none;
  }
  .footer-links
  {
    padding-left:0;
    list-style:none
  }
  .footer-links li
  {
    display:block
  }
  .footer-links a
  {
    color:#737373
  }
  .footer-links a:active,.footer-links a:focus,.footer-links a:hover
  {
    color:#3366cc;
    text-decoration:none;
  }
  .footer-links.inline li
  {
    display:inline-block
  }
  .site-footer .social-icons
  {
    text-align:center
  }
  .site-footer .social-icons a
  {
    width:40px;
    height:40px;
    line-height:40px;
    margin-left:6px;
    margin-right:0;
    border-radius:100%;
    background-color:#33353d
  }
  .copyright-text
  {
    text-align: center;
  }

  